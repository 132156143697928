export const MENUS = {
    home: [
        {
            title: 'Beranda',
            icon: '',
            link: '/dashboard'
        },
        {
            title: 'Kategori',
            icon: '',
            link: '/categories'
        },
        {
            title: 'Berita',
            icon: '',
            link: '/news'
        }
    ],
}